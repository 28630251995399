import React, { useState } from "react";
import { Link } from "react-router-dom";

import enFlag from "./flags/us.jpg";
import uzFlag from "./flags/uz.jpg";
import russiaFlag from "./flags/russia.jpg";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import i18next from "i18next";

// get the languages
const Languages = {
  uz: {
    name: "O'zbekcha",
    slug: "uz",
    flag: uzFlag,
  },
  en: {
    name: "English",
    slug: "en",
    flag: enFlag,
  },
  ru: {
    name: "Русский",
    slug: "ru",
    flag: russiaFlag,
  },
};
const LanguageDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState(Languages[i18next.language]);

  /*
   * toggle language-dropdown
   */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    console.log(currentLang);
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle
        id="dropdown-languages"
        as="a"
        style={{
          background: "transparent",
          color: "transparent",
          borderColor: "transparent",
          paddingTop: "0px",
        }}
        onClick={toggleDropdown}
        className={`nav-link waves-effect waves-light ${
          dropdownOpen ? "show" : ""
        }`}
      >
        <img src={currentLang.flag} alt={currentLang.name} height="16" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu dropdown-menu-end">
        <div onClick={toggleDropdown}>
          {(Object.values(Languages) || []).map((lang, i) => {
            return (
              <div
                // to="#"
                onClick={() => {
                  console.log(lang.slug);
                  i18next.changeLanguage(lang.slug);
                  setCurrentLang(lang);
                }}
                className="dropdown-item notify-item c-pointer"
                key={i + "-lang"}
              >
                <img
                  src={lang.flag}
                  alt={lang.name}
                  className="me-1"
                  height="12"
                />{" "}
                <span className="align-middle">{lang.name}</span>
              </div>
            );
          })}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageDropdown;
