/* eslint-disable react/no-array-index-key, react/no-danger */
import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Cards from "@hasanboy93/react-cards-uz";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import axios from "axiosClient";
import moment from "moment";
import { toast } from "react-toastify";
import "@hasanboy93/react-cards-uz/dist/es/styles-compiled.css";
import { Tooltip } from "react-tooltip";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import {
  formatCreditCardNumber,
  formatExpirationDate,
  formatOtp,
  formatPhone,
} from "utils/formatCardValues";
import { currencyFormatter } from "utils/formatter";
import TransactionDetails from "components/TransactionDetails";

const PaymentTkt = () => {
  const { enquiryId } = useParams();

  const [price, setPrice] = useState(0);
  const [transactionId, setTransactionId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [enquiryDetails, setEnquiryDetails] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [examUi, setExamUi] = useState(null);
  const [isPost, setIsPost] = useState(false);
  const [forPayment, setForPayment] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [residence, setResidence] = useState("Uzbekistan");
  const [validate, setValidate] = useState({
    emailState: "",
    passportState: "",
  });
  const [dob, setDob] = useState(null);
  const [examDate, setExamDate] = useState(null);

  const [cardDetails, setCardDetails] = useState({
    number: "",
    expiry: "",
    cvc: "",
    name: "",
    focus: "",
    phone: "",
    otp: "",
  });

  const [searchParams] = useSearchParams();
  const partner = searchParams.get("pr");

  const handleInputChange = (evt) => {
    let { name, value } = evt.target;
    let newValue;
    if (name === "number") {
      value = formatCreditCardNumber(value);
    } else if (name === "expiry") {
      value = formatExpirationDate(value);
    } else if (name === "phone") {
      value = formatPhone(value);
    } else if (name === "otp") {
      value = formatOtp(value);
    }
    setCardDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputFocus = (evt) => {
    setCardDetails((prev) => ({ ...prev, focus: evt.target.name }));
  };
  const handleResidenceChange = (e) => {
    setResidence(e.value);
  };

  const handleValuesChange = (e) => {
    if (residence === "Uzbekistan" && e.target.name === "passportId") {
      setEnquiryDetails({
        ...enquiryDetails,
        [e.target.name]: e.target.value.toUpperCase().replace(" ", ""),
      });
    } else {
      let value = e.target.value;

      setEnquiryDetails({ ...enquiryDetails, [e.target.name]: value });
    }
    setErrorMessage("");
  };

  const validatePassportId = (e) => {
    let rex = /[A-Z]{2}[0-9]{7}/;
    if (rex.test(e.target.value)) {
      setValidate({ ...validate, passportState: "has-success" });
    } else {
      setValidate({ ...validate, passportState: "has-danger" });
    }
  };

  const validateEmail = (e) => {
    let rex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (rex.test(e.target.value)) {
      setValidate({ ...validate, emailState: "has-success" });
    } else {
      setValidate({ ...validate, emailState: "has-danger" });
    }
  };

  const regionOptions = [
    { label: "Andijan", value: "Andijan" },
    { label: "Bukhara", value: "Bukhara" },
    { label: "Fergana", value: "Fergana" },
    { label: "Jizzakh", value: "Jizzakh" },
    { label: "Karakalpakstan", value: "Karakalpakstan" },
    { label: "Kashkadaryo", value: "Kashkadaryo" },
    { label: "Khorazm", value: "Khorazm" },
    { label: "Namangan", value: "Namangan" },
    { label: "Navoi", value: "Navoi" },
    { label: "Samarkand", value: "Samarkand" },
    { label: "Sirdaryo", value: "Sirdaryo" },
    { label: "Surkhondaryo", value: "Surkhondaryo" },
    { label: "Tashkent", value: "Tashkent" },
    { label: "Tashkent Region", value: "Tashkent Region" },
  ];
  const handleRegionChange = (e) => {
    setEnquiryDetails({ ...enquiryDetails, region: e.value });
  };

  const paymentOptions = [
    { label: "Test fee", value: "Test fee" },
    { label: "Transfer", value: "Transfer" },
    { label: "Extra TRF", value: "Extra TRF" },
    { label: "EOR (re-mark)", value: "EOR (re-mark)" },
    { label: "OSR", value: "OSR" },
  ];
  const handlePaymentOptionChange = async (e) => {
    setEnquiryDetails({ ...enquiryDetails, module: e.value });

    const price = await axios.get(
      `/api/price?exam=${enquiryDetails.exam}&module=${e.value}`
    );

    let total = Math.ceil((price.data.priceAmount * 100) / 99);

    setPrice({
      exam: price.data.priceAmount,
      per: total - price.data.priceAmount,
      total: total,
    });
    setShowCard(true);
  };

  const handlePrePayment = (e) => {
    e.preventDefault();
    // console.log(cardDetails);
    let cardNumber = cardDetails.number.replace(/\s/g, "");
    if (cardNumber.length !== 16) {
      return toast.error(`Please enter correct 16-digit card number`);
    }
    if (cardDetails.phone.length !== 9) {
      return toast.error(`Please enter correct 9-digit phone number`);
    }
    let year = cardDetails.expiry.split("/")[1];
    let month = cardDetails.expiry.split("/")[0];

    axios
      .post(`/api/payment/upay/pre`, {
        phoneNumber: cardDetails.phone,
        cardNumber: cardNumber,
        cardExpire: `${year}${month}`,
        enquiryId: `${enquiryDetails.pinpp}:${enquiryDetails.passportId}:${
          enquiryDetails.firstName
        } ${enquiryDetails.lastName}:${moment(
          enquiryDetails.examDate || examDate
        ).format("DD.MM.YYYY")}:${enquiryDetails.region}:${
          enquiryDetails.exam
        }`,
        amount: price.total,
        // amount: 1000,
      })
      .then((result) => {
        if (result.status === 200) {
          if (!result.data.success) {
            toast.error(result.data.description);
          } else {
            setTransactionId(result.data.transactionId);
            setIsPost(true);
          }
        } else {
          setErrorMessage(result.data);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          toast.error(err.response.data.description);
        } else {
          toast.error("Please try again.");
        }
      });
  };

  const handlePostPayment = (e) => {
    e.preventDefault();
    // console.log(cardDetails);

    if (cardDetails.otp === "") {
      return toast.error(
        `Please enter one-time code that was sent to your phone number`
      );
    }

    axios
      .post(`/api/payment/upay/post`, {
        transactionId,
        otp: cardDetails.otp,
        enquiryId,
        examDate: moment(examDate).format("DD/MM/YYYY"),
        ...enquiryDetails,
        candidateName: `${enquiryDetails.firstName} ${enquiryDetails.lastName}`,
        candidatePassport: enquiryDetails.passportId,
        candidateDob: moment(dob).format("DD/MM/YYYY"),
      })
      .then((result) => {
        if (result.status === 200) {
          setShowSuccess(true);
          setIsPost(false);
          setShowPaymentForm(false);
          setTransactionDetails(result.data.transaction);
        } else {
          setErrorMessage(result.data);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.data.description || "Error, try again");
      });
  };

  const handleGetPin = (e) => {
    e.preventDefault();
    if (residence === "Uzbekistan") {
      if (
        validate.passportState === "has-success" &&
        enquiryDetails.passportId &&
        dob
      ) {
        axios
          .post(`/api/check-details`, {
            docNumber: enquiryDetails.passportId,
            dob: moment(dob).format("YYYY-MM-DD"),
          })
          .then((result) => {
            if (result.status === 200) {
              setLoading(false);
              setEnquiryDetails({
                ...enquiryDetails,
                firstName: result.data.firstName,
                lastName: result.data.lastName,
                pinpp: result.data.pinpp,
              });
              setShowForm(true);
            } else if (result.status === 404) {
              toast.error(
                examUi === "TRKI"
                  ? "Неверный номер паспорта или дата рождения, пожалуйста, проверьте"
                  : "Passport ID or Date of Birth is wrong, please check"
              );
            }
          })
          .catch((err) => {
            if (err.response.status === 404) {
              toast.error(
                examUi === "TRKI"
                  ? "Неверный номер паспорта или дата рождения, пожалуйста, проверьте"
                  : "Passport ID or Date of Birth is wrong, please check"
              );
            }
          });
      }
    } else {
      setShowForm(true);
      setEnquiryDetails({
        ...enquiryDetails,
        firstName: "",
        lastName: "",
        pinpp: "0",
      });
    }
    setForPayment(true);
  };

  useEffect(() => {
    setLoading(true);
    window.location.href = "https://payment.edu-action.uk/";
    // if (enquiryId === "ielts") {
    //   setEnquiryDetails({ exam: "IELTS" });
    //   setForPayment(false);
    //   setLoading(false);
    // } else {
    //   if (typeof Number(enquiryId) === "number") {
    //     axios.get(`/api/enquiry-details/${enquiryId}`).then(async (result) => {
    //       if (result.status === 200) {
    //         setEnquiryDetails(result.data);
    //         // console.log(result.data);
    //         if (result.data.status === "Expired") {
    //           setForPayment(false);
    //         } else if (result.data.status === "Paid") {
    //           setForPayment(false);
    //           axios.get(`/api/payment-details/${enquiryId}`).then((res) => {
    //             if (res.status === 200) {
    //               setTransactionDetails(res.data);
    //             }
    //           });
    //         } else {
    //           setForPayment(true);
    //           const price = await axios.get(
    //             `/api/price?exam=${result.data.exam}&module=${result.data.module}`
    //           );
    //           let total = Math.ceil((price.data.priceAmount * 100) / 99);

    //           setPrice({
    //             exam: price.data.priceAmount,
    //             per: total - price.data.priceAmount,
    //             total: total,
    //           });
    //           setShowCard(true);
    //         }
    //         setLoading(false);
    //       } else {
    //         setErrorMessage(result.data);
    //         setLoading(false);
    //       }
    //     });
    //   } else {
    //     console.log("invalid enquiry ID");
    //     setErrorMessage("Invalid enquiry ID");
    //   }
    // }
  }, []);

  return loading ? null : (
    <div className="landing-page">
      <div className="main-container">
        <div className="content-container" id="home">
          <div className="section home">
            <div className="container">
              <div className="row home-row pt-2">
                <div className="col-12 d-block mt-3 text-center">
                  <img
                    src="/static/img/EA-vertical-white.png"
                    alt="logo"
                    className="logo-white"
                  />
                </div>
                <div className="col-12 ">
                  {showSuccess && (
                    <div className="home-text">
                      <div className="display-1 mt-5 pt-5">
                        {examUi === "TRKI"
                          ? "Вы успешно подали заявку с "
                          : "You have successfully paid for your application "}
                        {enquiryDetails.exam === "IELTS" ? (
                          <strong>IELTS {enquiryDetails.module}</strong>
                        ) : (
                          <strong>ID# {enquiryId}</strong>
                        )}
                        .
                        <p>
                          {examUi === "TRKI"
                            ? "Пожалуйста, проверьте свою электронную почту, чтобы получить дополнительную информацию."
                            : "Please, check your email to get further information."}
                        </p>
                        {transactionDetails && (
                          <TransactionDetails
                            transactionDetails={transactionDetails}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {!showSuccess && (
                    <div className="home-text">
                      <div className="display-1 mt-5 pt-5">
                        {forPayment
                          ? "Payment"
                          : enquiryDetails.exam === "IELTS"
                          ? "Payment"
                          : `Your enquiry has already been ${enquiryDetails.status.toLowerCase()}!`}
                        <br />
                        <br />
                        {transactionDetails && (
                          <TransactionDetails
                            transactionDetails={transactionDetails}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className={`${forPayment ? "col-12" : "col-8"}`}>
                  {showPaymentForm && (
                    <div
                      className="card mt-2"
                      style={{ borderRadius: "0.75rem" }}
                    >
                      <div className="card-body text-left">
                        <Form>
                          <Row>
                            {enquiryDetails.exam === "IELTS" ? (
                              <Col md={"6"}>
                                <Form>
                                  <h4 className="mt-4">
                                    {examUi === "TRKI"
                                      ? "Пожалуйста, заполните нижеприведенную информацию:"
                                      : "Please, fill the details below:"}
                                  </h4>
                                  <div
                                    className=" mt-2"
                                    style={{ borderRadius: "0.75rem" }}
                                  >
                                    <div className="card-body text-left">
                                      <FormGroup>
                                        <Label>
                                          {examUi === "TRKI"
                                            ? "Страна Проживания"
                                            : "Country of Residence"}
                                        </Label>
                                        <Select
                                          required
                                          className="react-select mb-3"
                                          value={{
                                            label: residence,
                                            value: residence,
                                          }}
                                          name="residence"
                                          options={[
                                            {
                                              label: "Uzbekistan",
                                              value: "Uzbekistan",
                                            },
                                            {
                                              label: "Other",
                                              value: "Other",
                                            },
                                          ]}
                                          onChange={handleResidenceChange}
                                        />
                                      </FormGroup>
                                      <FormGroup>
                                        <Label>
                                          {examUi === "TRKI"
                                            ? "Номер паспорта или ID (напр. AA1234567)"
                                            : "Passport or ID Number (e.g. AA1234567)"}
                                        </Label>
                                        <Input
                                          required
                                          type="text"
                                          name="passportId"
                                          valid={
                                            residence === "Uzbekistan"
                                              ? validate.passportState ===
                                                "has-success"
                                              : true
                                          }
                                          invalid={
                                            residence === "Uzbekistan"
                                              ? validate.passportState ===
                                                "has-danger"
                                              : false
                                          }
                                          value={enquiryDetails.passportId}
                                          disabled={showForm}
                                          onChange={(e) => {
                                            validatePassportId(e);
                                            handleValuesChange(e);
                                          }}
                                        />{" "}
                                        <FormFeedback>
                                          {examUi === "TRKI"
                                            ? "Пожалуйста, введите правильный номер паспорта или ID."
                                            : "Please input a correct passport or ID number."}
                                        </FormFeedback>
                                      </FormGroup>
                                      <FormGroup>
                                        <Label>
                                          {examUi === "TRKI"
                                            ? "Дата рождения"
                                            : "Date of Birth"}
                                        </Label>
                                        <DatePicker
                                          className={"form-control"}
                                          disabled={showForm}
                                          calendarIcon={
                                            <i className="simple-icon-calendar" />
                                          }
                                          clearIcon={
                                            <i className="iconsminds-close" />
                                          }
                                          dayPlaceholder="DD"
                                          monthPlaceholder="MM"
                                          yearPlaceholder="YYYY"
                                          format="dd/MM/y"
                                          minDate={
                                            new Date(
                                              new Date().setFullYear(
                                                new Date().getFullYear() - 100
                                              )
                                            )
                                          }
                                          value={dob ? dob : null}
                                          showLeadingZeros={true}
                                          onCalendarOpen={() => {
                                            if (!dob) {
                                              setDob(
                                                new Date(
                                                  new Date().setFullYear(
                                                    new Date().getFullYear() -
                                                      16
                                                  )
                                                )
                                              );
                                            }
                                          }}
                                          shouldOpenCalendar={({ reason }) =>
                                            reason !== "focus"
                                          }
                                          onChange={(v) => {
                                            setDob(v);
                                          }}
                                        />
                                      </FormGroup>
                                      {!showForm ? (
                                        <button
                                          type="submit"
                                          className="btn btn-light btn-xl  mb-2"
                                          onClick={(e) => handleGetPin(e)}
                                        >
                                          {examUi === "TRKI"
                                            ? "ДАЛЕЕ"
                                            : "PROCEED"}
                                        </button>
                                      ) : null}
                                      {showForm ? (
                                        <>
                                          <FormGroup>
                                            <Label>
                                              {examUi === "TRKI"
                                                ? "Имя (в паспорте на латинице, напр. Ivan)"
                                                : "First Name (per PASSPORT)"}
                                            </Label>
                                            <Input
                                              required
                                              type="text"
                                              name="firstName"
                                              disabled={
                                                residence === "Uzbekistan"
                                              }
                                              value={enquiryDetails.firstName}
                                              onChange={handleValuesChange}
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label>
                                              {examUi === "TRKI"
                                                ? "Фамилия (в паспорте на латинице, напр. Petrov)"
                                                : "Last Name (per PASSPORT)"}
                                            </Label>
                                            <Input
                                              required
                                              type="text"
                                              name="lastName"
                                              disabled={
                                                residence === "Uzbekistan"
                                              }
                                              value={enquiryDetails.lastName}
                                              onChange={handleValuesChange}
                                            />
                                          </FormGroup>{" "}
                                          <FormGroup>
                                            <Label>
                                              {examUi === "TRKI"
                                                ? "Электронная почта"
                                                : "Email"}
                                            </Label>
                                            <Input
                                              required
                                              type="email"
                                              name="email"
                                              valid={
                                                validate.emailState ===
                                                "has-success"
                                              }
                                              invalid={
                                                validate.emailState ===
                                                "has-danger"
                                              }
                                              value={enquiryDetails.email}
                                              onChange={(e) => {
                                                validateEmail(e);
                                                handleValuesChange(e);
                                              }}
                                            />
                                            <FormFeedback>
                                              {examUi === "TRKI"
                                                ? "Пожалуйста, введите правильный адрес электронной почты."
                                                : "Please input a correct email address."}
                                            </FormFeedback>
                                          </FormGroup>
                                          <FormGroup>
                                            <Label>Exam Date</Label>
                                            <DatePicker
                                              className={"form-control"}
                                              calendarIcon={
                                                <i className="simple-icon-calendar" />
                                              }
                                              clearIcon={
                                                <i className="iconsminds-close" />
                                              }
                                              dayPlaceholder="DD"
                                              monthPlaceholder="MM"
                                              yearPlaceholder="YYYY"
                                              format="dd/MM/y"
                                              minDate={
                                                new Date(
                                                  new Date().setFullYear(
                                                    new Date().getFullYear() -
                                                      100
                                                  )
                                                )
                                              }
                                              value={examDate ? examDate : null}
                                              showLeadingZeros={true}
                                              shouldOpenCalendar={({
                                                reason,
                                              }) => reason !== "focus"}
                                              onChange={(v) => {
                                                setExamDate(v);
                                              }}
                                            />
                                          </FormGroup>
                                          {examUi === "TRKI" ? null : (
                                            <FormGroup>
                                              <Label>Exam Region:</Label>
                                              <Select
                                                name="region"
                                                className="react-select mb-3"
                                                options={regionOptions}
                                                onChange={handleRegionChange}
                                              />
                                            </FormGroup>
                                          )}
                                          {enquiryDetails.exam !==
                                          "IELTS" ? null : (
                                            <FormGroup>
                                              <Label>Payment For:</Label>
                                              <Select
                                                name="regionLive"
                                                className="react-select mb-3"
                                                options={paymentOptions}
                                                onChange={
                                                  handlePaymentOptionChange
                                                }
                                              />
                                            </FormGroup>
                                          )}
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                </Form>
                              </Col>
                            ) : (
                              <Col md={forPayment ? "6" : "12"}>
                                <h3>Exam Details</h3>
                                <br />
                                <p>
                                  <strong>Candidate Name</strong>:{" "}
                                  {enquiryDetails.firstName}{" "}
                                  {enquiryDetails.lastName}
                                  <br />
                                  <strong>Exam</strong>: {enquiryDetails.exam}
                                  <br />
                                  <strong>Module</strong>:{" "}
                                  {enquiryDetails.module}
                                  <br />
                                  <strong>Date</strong>:{" "}
                                  {moment(enquiryDetails.examDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                  <br />
                                  <strong>Region</strong>:{" "}
                                  {enquiryDetails.region}
                                  <br />
                                  <strong>Status</strong>:{" "}
                                  {enquiryDetails.status}
                                  <br />
                                  {forPayment ? (
                                    <>
                                      <br />
                                      <strong>Exam Price</strong>:{" "}
                                      {currencyFormatter(
                                        `${price.exam}`,
                                        "UZS"
                                      )}
                                      <br />
                                      <strong>Processing Fee</strong>:{" "}
                                      {currencyFormatter(`${price.per}`, "UZS")}
                                      <br />
                                      <strong>Total to Pay</strong>:{" "}
                                      {currencyFormatter(
                                        `${price.total}`,
                                        "UZS"
                                      )}
                                    </>
                                  ) : null}
                                </p>
                              </Col>
                            )}
                            {showCard ? (
                              <Col md="6">
                                <Row>
                                  <Col md={7} className="mx-auto">
                                    {enquiryDetails.exam === "IELTS" ? (
                                      <>
                                        <br />
                                        <strong>Price</strong>:{" "}
                                        {currencyFormatter(
                                          `${price.exam}`,
                                          "UZS"
                                        )}
                                        <br />
                                        <strong>Processing Fee</strong>:{" "}
                                        {currencyFormatter(
                                          `${price.per}`,
                                          "UZS"
                                        )}
                                        <br />
                                        <strong>Total to Pay</strong>:{" "}
                                        {currencyFormatter(
                                          `${price.total}`,
                                          "UZS"
                                        )}
                                      </>
                                    ) : null}
                                    <h4 className="mt-4">
                                      {examUi === "TRKI"
                                        ? "Пожалуйста, заполните нижеприведенную информацию:"
                                        : "Please, fill the details below:"}
                                    </h4>
                                    <Cards
                                      number={cardDetails.number}
                                      expiry={cardDetails.expiry}
                                      cvc={cardDetails.cvc}
                                      name={cardDetails.name}
                                      focused={cardDetails.focus}
                                      placeholders={{ name: "" }}
                                      acceptedCards={["uzcard", "humo"]}
                                      preview={isPost}
                                    />
                                    <form>
                                      <div className="form-group mb-3 mt-4">
                                        <input
                                          type="tel"
                                          name="number"
                                          className="form-control"
                                          placeholder="Card Number"
                                          pattern="[\d| ]{16,22}"
                                          required
                                          value={cardDetails.number}
                                          onChange={handleInputChange}
                                          onFocus={handleInputFocus}
                                          disabled={isPost}
                                        />
                                      </div>
                                      <div className="form-group mb-3 mt-4">
                                        <input
                                          type="tel"
                                          name="expiry"
                                          className="form-control"
                                          placeholder="Valid Thru"
                                          pattern="\d\d/\d\d"
                                          required
                                          value={cardDetails.expiry}
                                          onChange={handleInputChange}
                                          onFocus={handleInputFocus}
                                          disabled={isPost}
                                        />
                                      </div>
                                      <div className="form-group mb-3 mt-4">
                                        <input
                                          type="tel"
                                          name="phone"
                                          className="form-control"
                                          placeholder="Phone Number"
                                          pattern=""
                                          required
                                          value={cardDetails.phone}
                                          onChange={handleInputChange}
                                          disabled={isPost}
                                          data-tooltip-id="phone"
                                          data-tooltip-content="Connected to your card, without 998. e.g. 943213322"
                                        />
                                        <Tooltip id="phone" />
                                      </div>
                                      {isPost ? (
                                        <div className="form-group mb-3 mt-4">
                                          <input
                                            type="tel"
                                            name="otp"
                                            className="form-control"
                                            placeholder="Enter the code from the SMS"
                                            pattern=""
                                            required
                                            value={cardDetails.otp}
                                            onChange={handleInputChange}
                                          />
                                          {/* <Tooltip id="phone" /> */}
                                        </div>
                                      ) : null}
                                    </form>
                                    {showCard && !isPost ? (
                                      <button
                                        type="submit"
                                        className="btn btn-light btn-xl  mb-2"
                                        onClick={(e) => handlePrePayment(e)}
                                      >
                                        {examUi === "TRKI"
                                          ? "ДАЛЕЕ"
                                          : "PROCEED"}
                                      </button>
                                    ) : null}
                                    {isPost ? (
                                      <button
                                        type="submit"
                                        className="btn btn-light btn-xl  mb-2"
                                        onClick={(e) => handlePostPayment(e)}
                                      >
                                        {examUi === "TRKI" ? "ДАЛЕЕ" : "PAY"}
                                      </button>
                                    ) : null}
                                  </Col>
                                </Row>
                                <br />
                                <Row>
                                  <p>
                                    <b>Secure Payment Processing:</b>
                                  </p>
                                  <p>
                                    Your security is our priority. We do not
                                    store your card details. All payments are
                                    securely processed through{" "}
                                    <strong>UPay</strong>, a trusted third-party
                                    service. Enjoy peace of mind with end-to-end
                                    encryption and OTP verification for added
                                    security.
                                  </p>
                                </Row>
                              </Col>
                            ) : null}
                          </Row>
                        </Form>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {!showSuccess && <div className="section"></div>}
        </div>
      </div>
    </div>
  );
};

export default PaymentTkt;
