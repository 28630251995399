import React, { useState } from "react";
import moment from "moment";
import "./calendar.css";

const Calendar = (props) => {
  const [dateContext, setDateContext] = useState(moment());
  const [today, setToday] = useState(moment());

  const [sDay, setSDay] = useState(null);

  const weekdaysShort = moment.weekdaysShort();
  const months = moment.months();

  let weekdays = weekdaysShort.map((day) => {
    return (
      <td key={day} className="week-day">
        {day}
      </td>
    );
  });

  let blanks = [];
  for (let i = 0; i < moment(dateContext).startOf("month").format("d"); i++) {
    blanks.push(
      <td key={i * 80} className="emptySlot">
        {""}
      </td>
    );
  }

  const onDayClick = (e, day) => {
    props.setSelectedDay(moment(dateContext).date(day).format("YYYY-MM-DD"));
    setSDay(day);
    // console.log("SELECTED DAY: ", day);
    // console.log("Context: ", moment(dateContext).date(day));
    // console.log("Context: ", moment("2022-09-14"));
  };

  let daysInMonth = [];
  for (let d = 1; d <= dateContext.daysInMonth(); d++) {
    let selectedClass = d === sDay ? " selected-day " : "";
    if (
      props.dates.includes(moment(dateContext).date(d).format("YYYY-MM-DD"))
    ) {
      daysInMonth.push(
        <td
          key={d}
          className={
            selectedClass +
            `day available-day ${
              props.details[moment(dateContext).date(d).format("YYYY-MM-DD")]
                ?.quick
                ? "quick-day"
                : ""
            } ${
              props.details[moment(dateContext).date(d).format("YYYY-MM-DD")]
                ?.low
                ? "low-day"
                : ""
            }`
          }
          onClick={(e) => {
            onDayClick(e, d);
          }}
        >
          {d}
        </td>
      );
    } else {
      daysInMonth.push(
        <td key={d} className={"day"}>
          {d}
        </td>
      );
    }
  }

  var totalSlots = [...blanks, ...daysInMonth];
  let rows = [];
  let cells = [];

  totalSlots.forEach((row, i) => {
    if (i % 7 !== 0) {
      cells.push(row);
    } else {
      let insertRow = cells.slice();
      rows.push(insertRow);
      cells = [];
      cells.push(row);
    }
    if (i === totalSlots.length - 1) {
      let insertRow = cells.slice();
      rows.push(insertRow);
    }
  });

  let trElems = rows.map((d, i) => {
    return <tr key={i * 100}>{d}</tr>;
  });

  const nextMonth = () => {
    setDateContext(moment(dateContext).add(1, "month"));
    props.setSelectedDay(null);
  };

  const prevMonth = () => {
    setDateContext(moment(dateContext).subtract(1, "month"));
    props.setSelectedDay(null);
  };

  return (
    <div className="calendar-container">
      <table className="calendar">
        <thead>
          <tr className="calendar-header">
            <td colSpan="1" className="py-1">
              <span className="py-2 month-click" onClick={prevMonth}>
                <i className="simple-icon-arrow-left" />
              </span>
            </td>
            <td colSpan="5">
              <span className="label-month">{dateContext.format("MMMM")}</span>{" "}
              <span className="label-year">{dateContext.format("Y")}</span>
            </td>
            <td colSpan="1" className="py-1">
              <span className=" py-2 month-click" onClick={nextMonth}>
                <i className="simple-icon-arrow-right" />
              </span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>{weekdays}</tr>
          {trElems}
        </tbody>
      </table>
      <p style={{ color: "white" }} className="mt-2">
        <span className="no-wrap">
          <span className="available-span">&nbsp;&nbsp;</span> Seats Available
        </span>{" "}
        <span className="no-wrap">
          <span className="quick-span ml-3">&nbsp;&nbsp;</span> Seats Filling
          Quickly
        </span>{" "}
        <span className="no-wrap">
          <span className="low-span ml-3">&nbsp;&nbsp;</span> Low Availability
        </span>
      </p>
    </div>
  );
};

export default Calendar;
