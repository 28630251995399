const ru = {
  mainTitle: "Регистрация на экзамен",
  selectExam: "Выберите экзамен",
  selectVenue: "Выберите место",
  selectModule: "Выберите модуль",
  selectLevel: "Выберите уровень",
  selectTestDate: "Выберите желаемую дату теста",
  fillDetails: "Пожалуйста, заполните данные ниже:",
  residenceCountry: "Страна проживания",
  Other: "Другое",
  Uzbekistan: "Узбекистан",
  passportNo: "Номер паспорта или ID (нп. AA1234567)",
  invalidPassportNo: "Пожалуйста, введите правильный номер паспорта или ID.",
  dob: "Дата рождения",
  wrongPassportOrDob:
    "Неправильный номер паспорта или дата рождения, проверьте",
  proceed: "ПРОДОЛЖИТЬ",
  firstName: "Имя (на паспорте)",
  lastName: "Фамилия (на паспорте)",
  gender: "Пол",
  male: "Мужской",
  female: "Женский",
  selectCertificateRegion: "Выберите регион, который вы получаете сертификат:",
  phoneNumber: "Номер телефона (включая 998)",
  invalidPhoneNumber: "Пожалуйста, введите правильный номер телефона.",
  email: "Email",
  invalidEmail: "Пожалуйста, введите правильный email.",
  attachPassport: "Прикрепить паспорт",
  readAgreed: "Я прочитал и согласен с ",
  termsConditions: "Условиями использования",
  submit: "Отправить",
  telephone: "Телефон",
  address1: "Адрес: 2-3 Проспект Бунёдкор, здания CIU, 4-й этаж",
  address2: "Ташкент, Узбекистан 100043",
  submittedSucces: "Вы успешно отправили заявку с ",
  checkEmail:
    "Пожалуйста, проверьте свой email для получения дальнейших информации.",
  payNow: "ОПЛАТИТЬ СЕЙЧАС",
  contacts: "Контакты",
  results: "Результаты",
  examDates: "Даты экзаменов",
  examsPayment: "Оплата экзаменов",
  selectBased: "Выберите режим проведения экзамена",
};

export default ru;
