const uz = {
  mainTitle: "Ro'yxatdan o'tish",
  selectExam: "Imtihonni tanlang",
  selectVenue: "Joynini tanlang",
  selectModule: "Modulni tanlang",
  selectLevel: "Darajani tanlang",
  selectTestDate: "Test uchun o'zingizga ma'qul sanani tanlang",
  fillDetails: "Ma'lumotlaringizni to'ldiring",
  residenceCountry: "Fuqarolingiz",
  Other: "Boshqa",
  Uzbekistan: "O'zbekiston",
  passportNo: "Passport yoki ID raqam (AA1234567)",
  invalidPassportNo: "Iltimos, to'g'ri passport yoki ID raqam kiriting.",
  dob: "Tug'ilgan sana",
  wrongPassportOrDob:
    "Iltimos, to'g'ri passport raqami yoki tug'ilgan sana kiriting.",
  proceed: "Davom etish",
  firstName: "Ism (PASSPORT bo'yicha)",
  lastName: "Familiya (PASSPORT bo'yicha)",
  gender: "Jins",
  male: "Erkak",
  female: "Ayol",
  selectCertificateRegion: "Sertifikat yuborish uchun shahar tanlang:",
  phoneNumber: "Telefon raqam (998 bilan)",
  invalidPhoneNumber: "Iltimos, to'g'ri telefon raqam kiriting.",
  email: "Email",
  invalidEmail: "Iltimos, to'g'ri email kiriting.",
  attachPassport: "Passport yuklash",
  readAgreed: "Ko'rsatlingan ",
  termsConditions: "shartlarga roziman",
  submit: "Yuborish",
  telephone: "Telefon",
  address1: "Manzil: 2-3 Bunyodkor shoh ko'cha, CIU Binosi 4-qavat,",
  address2: "Toshkent, O'zbekiston 100043",
  submittedSucces: "Siz muvaffaqaiyatli ro'yxatdan o'tdingiz ",
  checkEmail: "Keyingi qadamlar haqida bilish uchun emailingizni tekshiring. ",
  payNow: "To'lov qilish",
  contacts: "Aloqa",
  results: "Natijalar",
  examDates: "Imtihon sanalari",
  examsPayment: "Imtihonlar to'lovi",
  selectBased: "Imtihon topshirish usulini tanlang",
};

export default uz;
