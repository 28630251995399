const en = {
  mainTitle: "Exam Registration",
  selectExam: "Select the exam",
  selectVenue: "Select the venue",
  selectModule: "Select the module",
  selectLevel: "Select the level",
  selectTestDate: "Please select your preferred test date",
  fillDetails: "Please, fill the details below:",
  residenceCountry: "Country of Residence",
  Other: "Other",
  Uzbekistan: "Uzbekistan",
  passportNo: "Passport or ID Number (e.g. AA1234567)",
  invalidPassportNo: "Please input a correct passport or ID number.",
  dob: "Date of Birth",
  wrongPassportOrDob: "Wrong Passport or Date of Birth, please check",
  proceed: "PROCEED",
  firstName: "First Name (per PASSPORT)",
  lastName: "Last Name (per PASSPORT)",
  gender: "Gender",
  male: "Male",
  female: "Female",
  selectCertificateRegion: "Select the region you get your certificate:",
  phoneNumber: "Phone Number (including 998)",
  invalidPhoneNumber: "Please input a correct phone number.",
  email: "Email",
  invalidEmail: "Please input a correct email.",
  attachPassport: "Attach the Passport",
  readAgreed: "I have read and agree to the ",
  termsConditions: "Terms & Conditions",
  submit: "SUBMIT",
  telephone: "Telephone",
  address1: "Address: 2-3 Bunyodkor Avenue, CIU Building 4th floor,",
  address2: "Tashkent, Uzbekistan 100043",
  submittedSucces: "You have successfully submitted your application with ",
  checkEmail: "Please, check your email to get further information.",
  payNow: "PAY NOW",
  contacts: "Contacts",
  results: "Results",
  examDates: "Exam Dates",
  examsPayment: "Exams Payment",
  selectBased: "Select the mode",
};

export default en;
