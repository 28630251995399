import moment from "moment";
import React from "react";
import { currencyFormatter } from "utils/formatter";

export default function TransactionDetails({ transactionDetails }) {
  return (
    <div
      className="card mt-2 col-8 text-black"
      style={{ borderRadius: "0.75rem" }}
    >
      <div className="card-body text-left">
        <div className="col-12 d-block mt-3 text-center">
          <img
            src="/static/img/vertical.png"
            alt="logo"
            className="logo-white"
          />
        </div>
        <br />
        <h3 className="mb-2">Payment Details</h3>
        <p className="text-black">
          <strong>Candidate Name</strong>: {transactionDetails.firstName}{" "}
          {transactionDetails.lastName}
          <br />
          <strong>Passport / ID No</strong>:{" "}
          {transactionDetails.candidatePassport}
          <br />
          <strong>Exam</strong>: {transactionDetails.exam}
          <br />
          <strong>Module</strong>: {transactionDetails.module}
          <br />
          <strong>Exam Date</strong>:{" "}
          {transactionDetails.exam === "IELTS"
            ? transactionDetails.examDate
            : moment(transactionDetails.examDate).format("DD/MM/YYYY")}
          <br />
          <br />
          <strong>Transaction ID</strong>: {transactionDetails.transactionId}
          <br />
          <strong>Transaction Date</strong>:{" "}
          {moment(transactionDetails.createdAt)
            .add(5, "hours")
            .format("DD/MM/YYYY")}
          <br />
          <strong>
            Amount Paid:{" "}
            {currencyFormatter(`${transactionDetails.amount}`, "UZS")}
          </strong>
          <br />
        </p>
      </div>
    </div>
  );
}
